const validate = values => {
    const errors = {};
    if (!values.promotions || !values.promotions.length) {
        errors.promotions = {
            _error: "At least one promotions must be added"
        };
    } else {
        const promotionsArrayErrors = [];
        values.promotions.forEach((promotions, promotionsIndex) => {
            const promotionsErrors = {};
            if (!promotions || !promotions.PromotionName) {
                promotionsErrors.PromotionName = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.PromotionPercentage) {
                promotionsErrors.PromotionPercentage = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.PromotionType) {
                promotionsErrors.PromotionType = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.PromotionDates) {
                promotionsErrors.PromotionDates = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            // if (
            //     !promotions ||
            //     !promotions.PromotionMonday ||
            //     !promotions.PromotionTuesday ||
            //     !promotions.PromotionWednesday ||
            //     !promotions.PromotionThursday ||
            //     !promotions.PromotionFriday ||
            //     !promotions.PromotionSaturday ||
            //     !promotions.PromotionSunday ||
            //     !promotions.PromotionAllDays
            // ) {
            //     promotionsErrors.PromotionDays = "Required";
            //     promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            // }
            if (!promotions || !promotions.PromotionRooms) {
                promotionsErrors.PromotionRooms = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.PromotionRates) {
                promotionsErrors.PromotionRates = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.BookingcomNights) {
                promotionsErrors.BookingcomNights = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.BookingcomBookings) {
                promotionsErrors.BookingcomBookings = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.BookingcomCancellations) {
                promotionsErrors.BookingcomCancellations = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.BookingcomRevenue) {
                promotionsErrors.BookingcomRevenue = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.ExpediaNights) {
                promotionsErrors.ExpediaNights = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.ExpediaBookings) {
                promotionsErrors.ExpediaBookings = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.ExpediaCancellations) {
                promotionsErrors.ExpediaCancellations = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.ExpediaRevenue) {
                promotionsErrors.ExpediaRevenue = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.AgodaNights) {
                promotionsErrors.AgodaNights = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.AgodaBookings) {
                promotionsErrors.AgodaBookings = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.AgodaCancellations) {
                promotionsErrors.AgodaCancellations = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
            if (!promotions || !promotions.AgodaRevenue) {
                promotionsErrors.AgodaRevenue = "Required";
                promotionsArrayErrors[promotionsIndex] = promotionsErrors;
            }
        });
        if (promotionsArrayErrors.length) {
            errors.promotions = promotionsArrayErrors;
        }
    }
    return errors;
};

export default validate;
