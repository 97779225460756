export default (async function showResults(values) {
    const title = prompt("Please enter the name you want to save this file as");
    var items = JSON.parse(JSON.stringify(values.promotions));

    let calculateNights = items.map(
        totalNights =>
            parseFloat(totalNights.BookingcomNights) +
            parseFloat(totalNights.ExpediaNights) +
            parseFloat(totalNights.AgodaNights)
    );
    let calculateBookings = items.map(
        totalBookings =>
            parseFloat(totalBookings.BookingcomBookings) +
            parseFloat(totalBookings.ExpediaBookings) +
            parseFloat(totalBookings.AgodaBookings)
    );
    let calculateCancellations = items.map(
        totalCancellations =>
            parseFloat(totalCancellations.BookingcomCancellations) +
            parseFloat(totalCancellations.ExpediaCancellations) +
            parseFloat(totalCancellations.AgodaCancellations)
    );
    let calculateRevenue = items.map(
        totalRevenue =>
            parseFloat(totalRevenue.BookingcomRevenue) +
            parseFloat(totalRevenue.ExpediaRevenue) +
            parseFloat(totalRevenue.AgodaRevenue)
    );

    let calculateTotalRevenue = calculateRevenue.reduce((a, b) => a + b);

    calculateTotalRevenue = "R " + parseFloat(calculateTotalRevenue).toFixed(2);

    for (var key in items) {
        items[key].BookingcomRevenue = "R " + items[key].BookingcomRevenue;
        items[key].ExpediaRevenue = "R " + items[key].ExpediaRevenue;
        items[key].AgodaRevenue = "R " + items[key].AgodaRevenue;
        items[key].PromotionDays = "";

        if (items[key].PromotionMonday === true) {
            items[key].PromotionDays += "Mondays ";
        }
        if (items[key].PromotionTuesday === true) {
            items[key].PromotionDays += "Tuesdays ";
        }
        if (items[key].PromotionWednesday === true) {
            items[key].PromotionDays += "Wednesdays ";
        }
        if (items[key].PromotionThursday === true) {
            items[key].PromotionDays += "Thursdays ";
        }
        if (items[key].PromotionFriday === true) {
            items[key].PromotionDays += "Fridays ";
        }
        if (items[key].PromotionSaturday === true) {
            items[key].PromotionDays += "Saturdays ";
        }
        if (items[key].PromotionSunday === true) {
            items[key].PromotionDays += "Sundays";
        }

        if (items[key].PromotionAllDays === true) {
            items[key].PromotionDays =
                "Mondays Tuesdays Wednesdays Thursdays Fridays Saturdays Sundays";
        }
    }

    items.map(function(obj) {
        obj["Promotion Name"] = obj["PromotionName"];
        obj["Percentage"] = obj["PromotionPercentage"];
        obj["Type"] = obj["PromotionType"];
        obj["Rates"] = obj["PromotionRates"];
        obj["Rooms"] = obj["PromotionRooms"];
        obj["Dates"] = obj["PromotionDates"];
        obj["Days"] = obj["PromotionDays"];

        // Booking.com Information
        obj["Bookingcom Nights"] = obj["BookingcomNights"];
        obj["Bookingcom Bookings"] = obj["BookingcomBookings"];
        obj["Bookingcom Cancellations"] = obj["BookingcomCancellations"];
        obj["Bookingcom Revenue"] = obj["BookingcomRevenue"];

        // Expedia Information
        obj["Expedia Nights"] = obj["ExpediaNights"];
        obj["Expedia Bookings"] = obj["ExpediaBookings"];
        obj["Expedia Cancellations"] = obj["ExpediaCancellations"];
        obj["Expedia Revenue"] = obj["ExpediaRevenue"];

        // Agoda Information
        obj["Agoda Nights"] = obj["AgodaNights"];
        obj["Agoda Bookings"] = obj["AgodaBookings"];
        obj["Agoda Cancellations"] = obj["AgodaCancellations"];
        obj["Agoda Revenue"] = obj["AgodaRevenue"];

        // Delete old objects
        delete obj["PromotionName"];
        delete obj["PromotionPercentage"];
        delete obj["PromotionType"];
        delete obj["PromotionRates"];
        delete obj["PromotionRooms"];
        delete obj["PromotionDates"];
        delete obj["PromotionDays"];
        delete obj["PromotionMonday"];
        delete obj["PromotionTuesday"];
        delete obj["PromotionWednesday"];
        delete obj["PromotionThursday"];
        delete obj["PromotionFriday"];
        delete obj["PromotionSaturday"];
        delete obj["PromotionSunday"];
        delete obj["PromotionAllDays"];

        // Booking.com Information
        delete obj["BookingcomNights"];
        delete obj["BookingcomBookings"];
        delete obj["BookingcomCancellations"];
        delete obj["BookingcomRevenue"];

        // Expedia Information
        delete obj["ExpediaNights"];
        delete obj["ExpediaBookings"];
        delete obj["ExpediaCancellations"];
        delete obj["ExpediaRevenue"];

        // Agoda Information
        delete obj["AgodaNights"];
        delete obj["AgodaBookings"];
        delete obj["AgodaCancellations"];
        delete obj["AgodaRevenue"];

        return obj;
    });

    if (title !== null) {
        JSONToCSVConvertor(items, title, true);
    }
    ///////////////////////
    function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData =
            typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

        var CSV = "";
        //Set Report title in first row or line

        CSV += ReportTitle + "\r\n";
        CSV += "\r\n" + "Total Revenue";
        CSV += "\r\n" + calculateTotalRevenue + "\r\n\r\n";

        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = "";

            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                row += index + ",";
            }
            row += "Total Nights ,";
            row += "Total Bookings ,";
            row += "Total Cancellations ,";
            row += "Total Revenue ,";
            row = row.slice(0, -1);

            //append Label row with line break
            CSV += row + "\r\n";
        }

        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }
            row += '"' + calculateNights[i] + '",';
            row += '"' + calculateBookings[i] + '",';
            row += '"' + calculateCancellations[i] + '",';
            row += '"R ' + calculateRevenue[i].toFixed(2) + '",';

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + "\r\n";
        }

        if (CSV == "") {
            alert("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
});
