import React, { Component } from "react";
import "./Style.scss";
import eComLogo from "./assets/eComLogo.png";
import AddPromotion from "./components/AddPromotion";
import ShowResults from "./components/ShowResults.js";

export default class App extends Component {
    state = {
        title: "Enter the Title"
    };

    titleChange = event => {
        this.setState({ title: event.target.value });
    };

    submit = values => {
        // Do something with the form values
        console.log(values);
    };

    render() {
        return (
            <div className="margin-top-1">
                <div className="align-center">
                    <img src={eComLogo} alt="eCom South Africa Logo"></img>
                </div>
                <div className="promotion-holder">
                    <AddPromotion onSubmit={ShowResults} />
                </div>
            </div>
        );
    }
}
