import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "react-bootstrap/Button";
import RemoveIcon from "@material-ui/icons/Delete";
import ExcelIcon from "@material-ui/icons/Assessment";
import RefreshIcon from "@material-ui/icons/DeleteForever";
import { Field, FieldArray, reduxForm } from "redux-form";
import bookingcomLogo from "../assets/bookingcomLogo.jpg";
import expediaLogo from "../assets/expediaLogo.jpg";
import agodaLogo from "../assets/agodaLogo.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import validate from "./validate.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const renderField = ({
    input,
    label,
    type,
    className,
    placeholder,
    meta: { touched, error }
}) => (
    <div>
        <input
            {...input}
            type={type}
            placeholder={placeholder}
            className={className}
        />
        {touched && error && <span className="error-message">{error}</span>}
    </div>
);

const renderPromotion = ({
    fields,
    meta: { touched, error, submitFailed }
}) => (
    <div>
        {fields.map((promotion, index) => (
            <div key={index} className="form-promotion">
                <div className="align-center">
                    <div className="bordered-bottom">
                        <Row>
                            <Col>
                                <Field
                                    name={`${promotion}.PromotionName`}
                                    type="text"
                                    className="form-remove-outline form-promotion-header"
                                    component={renderField}
                                    placeholder="Promotion Name"
                                />
                            </Col>
                            <Col>
                                <Field
                                    name={`${promotion}.PromotionPercentage`}
                                    type="text"
                                    component={renderField}
                                    placeholder="Percentage"
                                    className="form-remove-outline form-promotion-header"
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col sm={3} className="bordered-right">
                            <Field
                                name={`${promotion}.PromotionDates`}
                                type="text"
                                component={renderField}
                                className="form-remove-outline"
                                placeholder="Dates"
                            />
                            <label className="promotion-days">
                                <Field
                                    name={`${promotion}.PromotionMonday`}
                                    type="checkbox"
                                    component={renderField}
                                    className="form-remove-outline"
                                />
                                Mon
                            </label>
                            <label className="promotion-days">
                                <Field
                                    name={`${promotion}.PromotionTuesday`}
                                    type="checkbox"
                                    component={renderField}
                                    className="form-remove-outline"
                                />
                                Tue
                            </label>
                            <label className="promotion-days">
                                <Field
                                    name={`${promotion}.PromotionWednesday`}
                                    type="checkbox"
                                    component={renderField}
                                    className="form-remove-outline"
                                />
                                Wed
                            </label>
                            <Row>
                                <Col>
                                    <label className="promotion-days">
                                        <Field
                                            name={`${promotion}.PromotionThursday`}
                                            type="checkbox"
                                            component={renderField}
                                            className="form-remove-outline"
                                        />
                                        Thur
                                    </label>
                                    <label className="promotion-days">
                                        <Field
                                            name={`${promotion}.PromotionFriday`}
                                            type="checkbox"
                                            component={renderField}
                                            className="form-remove-outline"
                                        />
                                        Fri
                                    </label>
                                    <label className="promotion-days">
                                        <Field
                                            name={`${promotion}.PromotionSaturday`}
                                            type="checkbox"
                                            component={renderField}
                                            className="form-remove-outline"
                                        />
                                        Sat
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label className="promotion-days">
                                        <Field
                                            name={`${promotion}.PromotionSunday`}
                                            type="checkbox"
                                            component={renderField}
                                            className="form-remove-outline"
                                        />
                                        Sun
                                    </label>
                                    <label className="promotion-days">
                                        <Field
                                            name={`${promotion}.PromotionAllDays`}
                                            type="checkbox"
                                            component={renderField}
                                            className="form-remove-outline"
                                        />
                                        All Days
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={`${promotion}.PromotionType`}
                                component="select"
                                className="form-remove-outline form-dropdown"
                            >
                                <option>Select the Promotion Type</option>
                                <option value="Basic">Basic</option>
                                <option value="Basic Weekdays">
                                    Basic Weekdays
                                </option>
                                <option value="Basic Weekends">
                                    Basic Weekends
                                </option>
                                <option value="Members Only">
                                    Members Only
                                </option>
                                <option value="Last Minute">Last Minute</option>
                                <option value="Minimum Stay">
                                    Minimum Stay
                                </option>
                                <option value="Capmaign">Campaign</option>
                            </Field>
                            <div className="form-second-heading">
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={3}>
                                        <img
                                            src={bookingcomLogo}
                                            alt="Booking.com Logo"
                                            className="align-center icon-style"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <img
                                            src={expediaLogo}
                                            alt="Booking.com Logo"
                                            className="align-center icon-style"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <img
                                            src={agodaLogo}
                                            alt="Booking.com Logo"
                                            className="align-center icon-style"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>Nights</Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.BookingcomNights`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.ExpediaNights`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.AgodaNights`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>Bookings</Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.BookingcomBookings`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.ExpediaBookings`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.AgodaBookings`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>Cancellations</Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.BookingcomCancellations`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.ExpediaCancellations`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.AgodaCancellations`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>Revenue</Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.BookingcomRevenue`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.ExpediaRevenue`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Field
                                            name={`${promotion}.AgodaRevenue`}
                                            type="number"
                                            component={renderField}
                                            placeholder="0"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col sm={3} className="bordered-left">
                            <Field
                                name={`${promotion}.PromotionRooms`}
                                type="text"
                                component={renderField}
                                className="form-remove-outline"
                                placeholder="Rooms"
                            />
                            <Field
                                name={`${promotion}.PromotionRates`}
                                type="text"
                                component={renderField}
                                className="form-remove-outline"
                                placeholder="Rate Plan"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="padding-5 border-top">
                    <Button
                        variant="outline-danger"
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Are you sure you want to remove this Promotion?"
                                )
                            ) {
                                fields.remove(index);
                            }
                        }}
                    >
                        <RemoveIcon />
                        Remove Promotion
                    </Button>
                </div>
            </div>
        ))}
        <Button
            variant="info"
            onClick={() => fields.push({})}
            className="add-button"
        >
            <AddIcon />
            Add Promotion
        </Button>
        {(touched || submitFailed) && error && <span>{error}</span>}
    </div>
);

let AddPromotion = props => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form className="buttonForm" onSubmit={handleSubmit}>
            <div className="sticky-right">
                <Button variant="success" type="submit" disabled={submitting}>
                    <ExcelIcon />
                    Print to Excel
                </Button>
                <div className="gap-button">
                    <Button
                        variant="danger"
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Are you sure you want to remove all Promotion Items?"
                                )
                            ) {
                                reset();
                            }
                        }}
                        disabled={submitting}
                    >
                        <RefreshIcon />
                        Remove All
                    </Button>
                </div>
            </div>
            <FieldArray name="promotions" component={renderPromotion} />
        </form>
    );
};

export default reduxForm({
    form: "fieldArrays",
    validate
})(AddPromotion);
